import { OpenInNew, Delete } from "@mui/icons-material"
import { Dialog, DialogTitle, Typography, DialogContent, List, ListItemButton, ListItemIcon, ListItemText, DialogActions, Button } from "@mui/material"
import { updateDoc, doc, collection } from "firebase/firestore"
import { deleteObject, ref } from "firebase/storage"
import { Job } from "../util/Types"
import { FirebaseFirestore, FirebaseStorage } from "../FirebaseApp"
import { useConfirm } from "material-ui-confirm"
import { useCallback } from "react"

export default function PhotoActionsDialog({selectedImage, job, onClose}: {job: Job, selectedImage: {name: string, url: string} | null, onClose: () => void}) {
    const confirm = useConfirm()
    const deletePhoto = useCallback(() => {
        confirm({
            title: 'Delete Photo',
            description: 'Are you sure you want to delete this photo? This action cannot be undone.',
            confirmationText: 'Delete',
            confirmationButtonProps: {
                variant: 'contained',
                color: 'error'
            },
            cancellationText: 'Cancel',
            contentProps: {
                dividers: true
            }
        }).then(async () => {
            onClose()
            await updateDoc(doc(collection(FirebaseFirestore, 'jobs'), job.id), { attachments: job.attachments.filter(a => a !== selectedImage?.name) }).catch(e => console.log(e))
            await deleteObject(ref(FirebaseStorage, `job_photos/${selectedImage?.name ?? ''}`)).catch(e => console.log(e))
        }, () => { })
    }, [confirm, onClose, selectedImage, job])
    return <Dialog maxWidth="xs" fullWidth open={selectedImage !== null} onClose={onClose}>
        <DialogTitle>
            Actions
            <Typography variant="body2" sx={{ color: 'text.secondary', lineBreak: 'anywhere' }}>{selectedImage?.name ?? "--"}</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
            <List>
                <ListItemButton onClick={onClose} href={selectedImage?.url ?? ""} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon><OpenInNew /></ListItemIcon>
                    <ListItemText primary="Open" />
                </ListItemButton>
                <ListItemButton selected={job.active} disabled={!job.active} onClick={deletePhoto}>
                    <ListItemIcon><Delete /></ListItemIcon>
                    <ListItemText primary="Delete" />
                </ListItemButton>
            </List>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="success" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}