import { Checkbox, Divider, FormControlLabel, Grid2 as Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, Stack, TextField, Typography } from "@mui/material";
import { Job } from "./util/Types";
import { useMemo, useState } from "react";
import VisuallyHiddenInput from "./util/VisuallyHiddenInput";

const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']

export const equipmentTypes = [
    'Skid steer',
    'Bucket',
    'Grapple',
    'Auger bit',
    'Stump grinder',
    'Large saw',
    'Stump dolly',
    'Grapple truck',
    'Climbing equiment',
    'Power pruner',
    'Yellow poles',
    'Pole saw'
]

export default function JobDetails(props: {job: Job}) {
    let formDisabled = useMemo(() => !props.job.active, [props.job])
    const [currentComplexity, setCurrentComplexity] = useState(props.job.complexity || 0)
    return <Stack direction='column' spacing={4} divider={<Divider flexItem/>}>
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant="h6">Customer Details</Typography>
            </Grid>
            <Grid size={12}>
                <TextField id="customerName" disabled={formDisabled} fullWidth defaultValue={props.job.customerName} variant="filled" label="Customer or business name" />
            </Grid>
            <Grid size={6}>
                <TextField id="contactName" disabled={formDisabled} fullWidth variant="filled" defaultValue={props.job.contactName} label="Contact name" helperText="If different than customer name" />
            </Grid>
            <Grid size={6}>
                <TextField id="phone" disabled={formDisabled} fullWidth variant="filled" defaultValue={props.job.phone} type="phone" label="Phone number" helperText="Digits only" />
            </Grid>
            <Grid size={12}>
                <TextField id="email" disabled={formDisabled} fullWidth variant="filled" defaultValue={props.job.email} type="email" label="Email address" />
            </Grid>
            <Grid size={12}>
                <FormControlLabel control={<Checkbox id="priority" disabled={formDisabled} defaultChecked={props.job.priority} color="error" />} label="Priority" />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField id="addressLine1" disabled={formDisabled} fullWidth defaultValue={props.job.address.line1} variant="filled" label="Line 1" />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField id="addressLine2" disabled={formDisabled} fullWidth defaultValue={props.job.address.line2} variant="filled" label="Line 2" />
            </Grid>
            <Grid size={{xs: 12, sm: 6}}>
                <TextField id="addressCity" disabled={formDisabled} fullWidth defaultValue={props.job.address.city} variant="filled" label="City" />
            </Grid>
            <Grid size={{xs: 6, sm: 4}}>
                <TextField id="addressZip" disabled={formDisabled} fullWidth defaultValue={props.job.address.zip} variant="filled" label="Zip code" />
            </Grid>
            <Grid size={{xs: 6, sm: 2}}>
                <Select inputProps={{ id: 'addressState'}} disabled={formDisabled} fullWidth variant="filled" label="State" defaultValue={props.job.address.state || "TX"}>
                    {states.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                </Select>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant="h6">Job Details</Typography>
            </Grid>
            <Grid size={{xs: 12}} sx={{px: 2}}>
                <VisuallyHiddenInput type="number" readOnly value={currentComplexity} id="complexity"/>
                <InputLabel sx={{ml: -2, mb: 1}}>Complexity</InputLabel>
                <Slider disabled={formDisabled} value={currentComplexity} onChange={(_, v) => setCurrentComplexity(v as number)} min={0} max={5} step={1} marks={[
                    {
                        value: 0,
                        label: 'Not set'
                    },
                    {
                        value: 1,
                        label: 'Cake'
                    },
                    {
                        value: 2,
                        label: 'Easy'
                    },
                    {
                        value: 3,
                        label: 'Medium'
                    },
                    {
                        value: 4,
                        label: 'Hard'
                    },
                    {
                        value: 5,
                        label: 'Expert'
                    }
                ]}/>
            </Grid>
            <Grid size={6}>
                <TextField id="estimatedHours" disabled={formDisabled} fullWidth variant="filled" label="Estimated hours" type="number" defaultValue={props.job.estimatedHours}/>
            </Grid>
            <Grid size={6}>
                <TextField id="estimatedPrice" disabled={formDisabled} fullWidth variant="filled" label="Estimated price" type="number" defaultValue={props.job.estimatedPrice} slotProps={{input: {startAdornment: <InputAdornment position="start">$</InputAdornment>}}} />
            </Grid>
            <Grid size={12}>
                <TextField id="notes" disabled={formDisabled} label="Notes" variant="filled" fullWidth multiline rows={10} defaultValue={props.job.notes} />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant="h6">Required Equipment</Typography>
            </Grid>
            {equipmentTypes.map(e => <Grid key={e} size={{xs: 6, sm: 4}}>
                <FormControlLabel control={<Checkbox id={`equipment_${e}`} disabled={formDisabled} defaultChecked={props.job.equipment.includes(e)} color="error" />} label={e} />
            </Grid>)}
            <Typography variant='caption' color="text.secondary">Job ID: <code>{props.job.id}</code></Typography>
        </Grid>
    </Stack>
}