// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_ATS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_ATS_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_ATS_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_ATS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_ATS_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ATS_FIREBASE_APP_ID
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig)

export const FirebaseFirestore = getFirestore(FirebaseApp)
export const FirebaseAuth = getAuth(FirebaseApp)
export const FirebaseStorage = getStorage(FirebaseApp)
