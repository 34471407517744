import { Stack, Typography } from "@mui/material"
import { version as reactVersion } from "react"
import { version as muiVersion } from "@mui/material/version"
import Fuse from "fuse.js"
import { SDK_VERSION as firebaseVersion } from "firebase/app"

export default function Version(props: {pt?: number}) {
    return <Stack direction='column' sx={{pt: props.pt}}>
        <Typography textAlign='center' color="text.secondary" sx={{ userSelect: 'none', cursor: 'default' }}>Version 0.3.2 (2024-11-9)</Typography>
        <Typography textAlign='center' fontSize='small' color="text.secondary" sx={{ userSelect: 'none', cursor: 'default' }}>React {reactVersion}, MUI {muiVersion}, Fuse {Fuse.version}, Firebase {firebaseVersion}</Typography>
    </Stack>
}