import { onSnapshot, collection } from "firebase/firestore"
import { useState, useEffect } from "react"
import { FirebaseFirestore } from "../FirebaseApp"

export default function useFirestore<T>(collectionName: string): T[] | null {
    const [data, setData] = useState<T[] | null>(null)
    useEffect(() => onSnapshot(collection(FirebaseFirestore, collectionName), snap => {
        setData(snap.docs.map(d => d.data() as T))
    }), [collectionName])
    return data
}